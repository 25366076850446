const getPrices = (plans: any, type: string) => {
  let prices = {
    year: 0,
    month: 0,
  };

  if (plans) {
    const yearPlan = plans.find(
      (plan: any) => plan.id === `filevert_${type}_yearly`
    );
    const monthPlan = plans.find(
      (plan: any) => plan.id === `filevert_${type}_monthly`
    );

    return {
      year: getHTPrices(yearPlan ? yearPlan.amount / 100 : prices.year, 20),
      month: getHTPrices(monthPlan ? monthPlan.amount / 100 : prices.month, 20),
    };
  }
};

export const getHTPrices = (price: number, taxrate: number): number =>
  Number((price / (1 + taxrate / 100)).toFixed(2));

export default getPrices;
