import React, { Component, CSSProperties } from "react";
import "./UploaderInput.css";

import { connect, ConnectedProps } from "react-redux";
import LoaderComponent from "../../../Loader/Loader";
import images from "../../../../assets/images";
import { i18n } from "../../../../utils";
import { MDBCol, MDBContainer, MDBRow, MDBTooltip } from "mdbreact";
import { isMobile } from "../../../../utils";
import userApi from "../../../../Api/userApi";

interface RootState {
  user: { user: any };
  transfer: { transfers: any };
  file: any[];
}

type BaseProps = {
  accept?: string;
  onFiles: (files: File[]) => void;
  getFilesFromEvent: (event: React.ChangeEvent<HTMLInputElement>) => Promise<File[]>;
  isFilesLoading: boolean;
  percent?: number;
  children?: React.ReactNode;
};

type State = {
  isSubmited: boolean;
  isFilesLoading: boolean;
  isModalOpen: boolean;
};

const mapState = (state: RootState) => ({
  user: state.user?.user,
  transfer: state.transfer.transfers,
  file: state.file,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = BaseProps & PropsFromRedux;

class UploaderInputComponent extends Component<Props, State> {
  private _isMounted: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmited: false,
      isFilesLoading: false,
      isModalOpen: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    
    // Ne faire la vérification que si l'utilisateur est connecté
    if (this.props.user) {
      try {
        await userApi.userVerification();
      } catch (error) {
        console.error('[UploaderInput] User verification error:', error);
      }
    }

    if (isMobile) {
      this.listenIfCancel();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  listenIfCancel = () => {
    if (this.props.file.length || this.state.isFilesLoading || !isMobile)
      return;

    setTimeout(() => {
      document
        .querySelectorAll(".uploader.action-container")
        ?.forEach((domInput) => {
          domInput.classList.add("mobile");
        });

      document.body.onclick = () => {
        setTimeout(() => {
          document
            .querySelectorAll(".uploader.action-container")
            ?.forEach((domInput) => {
              domInput.classList.remove("mobile");
            });
        }, 1);
      };
    }, 500);
  };

  handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this._isMounted) return;

    e.preventDefault();
    e.stopPropagation();
    
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    try {
      const chosenFiles = await this.props.getFilesFromEvent(e);
      await this.props.onFiles(chosenFiles);
    } catch (error) {
      console.error('[UploaderInput] Error:', error);
    }
  };

  renderInputLabel = () => {
    if (this.state.isSubmited) {
      return null;
    }

    const labelClassName = this.props.file.length
      ? "uploader action-container full hover-pointer fv_uploader_action_full_container text-left my-auto"
      : "uploader action-container empty hover-pointer fv_uploader_action_empty_container";

    return this.props.file.length ? (
      <label
        onClick={this.listenIfCancel}
        htmlFor={"input-file"}
        className={`${labelClassName} fv_input_file_full_label w-100 my-auto`}
        style={{ zIndex: 100 }}
      >
        <div>
          <span
            className={"uploader action main full lh-1 my-auto btn-action-up"}
          >
            <span className="lnr lnr-plus-circle"></span>
            {i18n._("add_files_label")}
          </span>
        </div>
      </label>
    ) : (
      <>
        <label
          onClick={this.listenIfCancel}
          htmlFor={"input-file"}
          className={`${labelClassName} fv_input_empty_file_label`}
        >
          <span className="lnr lnr-plus-circle up-circle"></span>

          <p className={"uploader action main empty text-white"}>
            {i18n._("drag_and_drop_label")}
            <span className={"uploader action alt text-white btn-action-up"}>
              {i18n._("drag_and_drop_alt_label")}
            </span>
          </p>
        </label>
      </>
    );
  };

  renderInputLabelDirectory = () => {
    const labelClassName = this.props.file.length
      ? "uploader action-container full hover-pointer fv_input_dir_full_label w-100 text-left my-auto"
      : "uploader action-container empty hover-pointer text-white fv_input_dir_empty_label";

    return (
      <label
        onClick={this.listenIfCancel}
        htmlFor={"input-directory"}
        className={labelClassName}
        style={{ left: 0, zIndex: 1000, position: "relative" }}
      >
        {!!this.props.file.length && (
          <img src={images.DirectoryIcon} height={35} alt="directory-icon" />
        )}
        <span
          className={`uploader action main full btn-action-up ${
            !this.props.file.length ? "text-white" : "lh-1 my-auto"
          }`}
        >
          <span className="lnr lnr-plus-circle"></span>
          {i18n._("add_directory_label")}
        </span>
      </label>
    );
  };

  render() {
    const { accept, isFilesLoading } = this.props;

    const inputStyle: CSSProperties =
      this.props.file.length && !isFilesLoading
        ? {
            position: "relative",
            left: 0,
            top: 0,
            height: "100%",
            width: 0,
            opacity: 0,
            zIndex: 10,
          }
        : {
            position: "fixed",
            left: 0,
            top: 50,
            height: "100vh",
            width: "100vw",
            opacity: 0,
          };

    return (
      <>
        <div className={this.props.file.length ? "w-75 d-flex" : ""}>
          {isFilesLoading && (
            <MDBContainer
              fluid
              className={
                "position-absolute h-100 w-100 text-center text-dark uploader action-container empty loading"
              }
              style={{
                top: "0%",
                maxHeight: "240px",
                maxWidth: "450px",
                borderRadius: "10px",
                zIndex: 999999,
                backgroundColor: "rgba(222,222,222, 1)",
              }}
            >
              <MDBRow className={"h-100 w-100 m-0 p-0"}>
                <MDBCol className={"mx-auto my-auto text-black"} size={"12"}>
                  <LoaderComponent size={"small"} />
                  <span>{i18n._("files_waiting_label")}</span>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          )}

          {this.renderInputLabel()}

          <input
            id={"input-file"}
            style={inputStyle}
            type="file"
            accept={accept}
            multiple
            onChange={this.handleFileChange}
          />

          {this.renderInputLabelDirectory()}

          <input
            id={"input-directory"}
            style={{ display: "none" }}
            type="file"
            accept={accept}
            multiple
            onChange={this.handleFileChange}
            // prettier-ignore
            //@ts-ignore
            webkitdirectory={'true'}
            mozdirectory={"true"}
            directory={"true"}
          />
        </div>
        {!this.props.file.length && this.props.children}
      </>
    );
  }
}

export default connector(UploaderInputComponent);
