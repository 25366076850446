import React from "react";
import "./UploaderForm.css";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBLink,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBTooltip,
} from "mdbreact";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { convertSize, i18n, wrapStr } from "../../../../utils";
import { appAction, transferAction } from "../../../../Store/Reducers";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import EmailFormComponent from "./UploaderEmailForm";
import { transferApi, userApi } from "../../../../Api";
import { Go } from "../../../../Constants";

interface Props {
  dispatch: Function;
  transfer: any;
  user: any;
  file: any;
  match?: any;
  submitCb: any;
  app: any;
  user_upload_size: {
    per_transfer_limit: number;
    total_transfer_limit: number;
  };
  current_uploaded_size: number;
}

interface TransferInfosModel {
  id?: string;
  transfer_type?: number;
  transfer_name: string;
  transfer_message: string;
  transfer_password: string;
  transfer_confirm_password: string;
  recipients: string[];
  for_team: boolean;
  auto_remove: boolean;
  auto_send: boolean;
}

interface State {
  transferInfos: TransferInfosModel;
  isFilesLoading: boolean;
  isPasswordModalOpen: boolean;
}

const defaultLimitInGo: number = Go * 2;

const hackTransferType = Number(window.location.pathname.startsWith("/u/"));

class UploaderFormComponent extends React.Component<Props, State> {
  filesNb: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      isPasswordModalOpen: false,
      transferInfos: {
        transfer_name: "",
        transfer_message: "",
        transfer_password: "",
        transfer_confirm_password: "",
        recipients: [],
        auto_remove: false,
        auto_send: false,
        for_team: false,
      },
      isFilesLoading: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload() {
    // const response = window.confirm("You will cancel your transfer ?");
    // alert("OK");
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);

    const { user } = this.props;

    if (user) {
      const { config } = user;

      if (!config || !config.message || !config.title) return;
    }
  }

  handleChangeAutoremove = (evt: any) => {
    if (this.props.transfer.isLocked) return;

    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    const transferInfos = {
      ...this.state.transferInfos,
      auto_remove: value,
    };

    return this.setState({ ...this.state, transferInfos });
  };

  handleChangeEmailForm = (key: string, value: any, recipients?: string[]) => {
    if (recipients)
      return this.setState({
        ...this.state,
        transferInfos: {
          ...this.state.transferInfos,
          [key]: value,
          recipients: recipients.filter(
            (em: string) =>
              recipients?.indexOf(em) === recipients?.lastIndexOf(em)
          ),
        },
      });

    this.setState({
      ...this.state,
      transferInfos: { ...this.state.transferInfos, [key]: value },
    });
  };

  handleRestart = (file: IFileWithMeta): void => {
    file.restart();
  };

  isValidLimit = () => {
    const {
      user_upload_size: { per_transfer_limit },
      transfer: { totalSize },
    } = this.props;
    return per_transfer_limit >= totalSize;
  };
  handleCustomSubmit = async () => {
    if (!this.isValidLimit()) return;

    this.props.dispatch(transferAction.lockTransfer(true));
    let { transferInfos } = this.state;
    const startTime = Date.now();
    const progress = {
      last: {
        timestamp: 0,
        loaded: 0,
      },
      current: {
        timestamp: 0,
        loaded: 0,
      },
    };
    this.props.dispatch(
      transferAction.setInfos({
        ...transferInfos,
        startTime,
        progress,
      })
    );

    if (!transferInfos.transfer_name) {
      transferInfos.transfer_name = wrapStr(this.props.file[0].meta.name);
    }

    if (this.props.transfer.transfer_type)
      transferInfos.transfer_type = this.props.transfer.transfer_type;

    if (this.props.transfer.id) transferInfos.id = this.props.transfer.id;

    const pl = {
      auto_remove: transferInfos.auto_remove,
      auto_send: transferInfos.auto_send,
      for_team: transferInfos.for_team,
      recipients: transferInfos.recipients,
      transfer_message: transferInfos.transfer_message,
      transfer_name: transferInfos.transfer_name,
      transfer_password: transferInfos.transfer_password,
      transfer_notification: Boolean(
        this.props?.app[appAction.USER_UPLOAD_FAVORITE_METHOD] || 0
      ),
    };

    const transfer = await transferApi.createTransfer(
      transferInfos.id ? { ...pl, transfer_id: transferInfos.id } : pl
    );

    // Ajout de la correction ici - Mise à jour du store avec transfer_id et bucket_id
    this.props.dispatch(
      transferAction.setInfos({
        ...this.props.transfer,
        transfer_id: transfer.transfer_id,
        bucket_id: transfer.bucket_id,
      })
    );

    return this.props.submitCb(transfer);
  };

  onChangeTab = (current: number) => {
    this.props.dispatch(appAction.setUserUploadFavoriteMethod(current));
  };

  handleBlur = () => {
    const { transfer_password, transfer_confirm_password } =
      this.state.transferInfos;
    const passwordField = document.querySelector(
      'input[name="transfer_password"]'
    );
    const confirmPasswordField = document.querySelector(
      'input[name="transfer_confirm_password"]'
    );

    if (!transfer_password.length && !transfer_confirm_password.length) return;

    if (
      !transfer_password.match(
        /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z|a-z]).*$/
      ) &&
      transfer_password.length
    ) {
      // prettier-ignore
      //@ts-ignore
      passwordField.setCustomValidity('invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.add('is-invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.remove('is-valid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.remove('is-valid');
    } else if (
      transfer_password !== transfer_confirm_password &&
      transfer_confirm_password.length
    ) {
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.setCustomValidity('invalid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.add('is-invalid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.remove('is-valid');
    } else {
      if (transfer_password !== transfer_confirm_password) {
        // prettier-ignore
        //@ts-ignore
        passwordField.setCustomValidity('');
        // prettier-ignore
        //@ts-ignore
        passwordField.classList.remove('is-invalid');
        // prettier-ignore
        //@ts-ignore
        passwordField.classList.add('is-valid');
      } else {
        // prettier-ignore
        //@ts-ignore
        confirmPasswordField.setCustomValidity('');
        // prettier-ignore
        //@ts-ignore
        confirmPasswordField.classList.remove('is-invalid');
        // prettier-ignore
        //@ts-ignore
        confirmPasswordField.classList.add('is-valid');
      }
    }
  };

  handleSubmit = (evt: any) => {
    const { transfer_password, transfer_confirm_password } =
      this.state.transferInfos;
    evt.preventDefault();
    evt.stopPropagation();

    if (!~evt.target.className.indexOf("was-validated"))
      evt.target.className += " was-validated";

    const passwordField = document.querySelector(
      'input[name="transfer_password"]'
    );
    const confirmPasswordField = document.querySelector(
      'input[name="transfer_confirm_password"]'
    );

    if (
      !transfer_password.match(
        /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z|a-z]).*$/
      ) &&
      transfer_password.length
    ) {
      // prettier-ignore
      //@ts-ignore
      passwordField.setCustomValidity('invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.add('is-invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.remove('is-valid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.remove('is-valid');
    } else if (transfer_password !== transfer_confirm_password) {
      // prettier-ignore
      //@ts-ignore
      passwordField.setCustomValidity('invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.add('is-invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.remove('is-valid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.setCustomValidity('invalid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.add('is-invalid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.remove('is-valid');
    } else {
      // prettier-ignore
      //@ts-ignore
      passwordField.setCustomValidity('');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.remove('is-invalid');
      // prettier-ignore
      //@ts-ignore
      passwordField.classList.add('is-valid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.setCustomValidity('');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.remove('is-invalid');
      // prettier-ignore
      //@ts-ignore
      confirmPasswordField.classList.add('is-valid');

      setTimeout(
        () => this.setState({ ...this.state, isPasswordModalOpen: false }),
        1000
      );
    }
  };

  render = () => {
    const { transfer } = this.props;
    const { transferInfos } = this.state;
    const isValidLimit = this.isValidLimit();

    if (transfer.transfer_type || transfer.type || hackTransferType)
      return (
        <MDBRow
          style={{ display: this.state.isFilesLoading ? "none" : "" }}
          className={"h-75"}
        >
          <MDBCol size={"12"} className={"my-auto"}>
            <MDBBtn
              onClick={this.handleCustomSubmit}
              className={
                "btn btn-block Ripple-parent aqua-gradient waves-effect waves-light py-2 mx-auto"
              }
            >
              {i18n._("btn_transfer_label")}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      );

    const isTeamAuthDisabled =
      (transferInfos?.auto_send ||
        !this.props?.user?.permissions?.transfer_authentication?.value) &&
      !this.props.user?.members?.length &&
      !this.props?.user?.referer_id;

    return (
      <Tabs
        onSelect={this.onChangeTab}
        defaultIndex={
          this.props?.app[appAction.USER_UPLOAD_FAVORITE_METHOD] || 0
        }
      >
        {!!this.props?.user?.role ? (
          <TabList
            className={"react-tabs__tab-list text-left pt-3 text-primary"}
          >
            <Tab disabled={this.props?.transfer?.isLocked}>
              <span className="lnr lnr-link"></span>
              {i18n._("form_upload_tab_link_label")}
            </Tab>

            <Tab disabled={transfer.isLocked}>
              <span className="lnr lnr-envelope"></span>
              {i18n._("form_upload_tab_email_label")}
            </Tab>

            <div
              className={"features-btn d-flex"}
              style={{ right: 0, top: 55 }}
            >
              <div className={"ico-trans"}>
                <div className={"px-0"}>
                  <MDBTooltip domElement tag={"span"} placement={"top"}>
                    <i
                      className={`fas fa-lock fa-2x hover-pointer ${
                        transferInfos.transfer_password
                          ? "green-text"
                          : "text-dark"
                      } ${
                        (!this.props.user?.role ||
                          this?.props?.user?.permissions
                            ?.transfer_authentication?.value === false) &&
                        "disabled"
                      }`}
                      onClick={
                        !this.props.user?.role ||
                        this?.props?.user?.permissions?.transfer_authentication
                          ?.value === false
                          ? undefined
                          : () =>
                              this.setState({
                                ...this.state,
                                isPasswordModalOpen: true,
                              })
                      }
                    />
                    <span>{i18n._("password_explain")}</span>
                  </MDBTooltip>
                </div>
              </div>
              <div className={"ico-trans"}>
                <MDBTooltip domElement tag={"span"} placement={"top"}>
                  <i
                    aria-disabled={isTeamAuthDisabled}
                    className={`fas fa-user-shield fa-2x ${
                      transferInfos.for_team ? "green-text" : "text-dark"
                    } ${isTeamAuthDisabled ? "disabled" : "hover-pointer"}`}
                    onClick={
                      isTeamAuthDisabled
                        ? undefined
                        : async () => {
                            if (transferInfos.for_team) {
                              this.setState({
                                ...this.state,
                                transferInfos: {
                                  ...this.state.transferInfos,
                                  recipients: [],
                                  for_team: !transferInfos.for_team,
                                },
                              });
                              return this.handleChangeEmailForm(
                                "for_team",
                                false,
                                []
                              );
                            }
                            const userMembers = await userApi.getTeamMembers(
                              this.props.user?.id
                            );
                            const recipients = userMembers.map(
                              ({ email }: any) => email
                            );
                            // this.setState({...this.state, recipients, for_team: !this.state.for_team})
                            this.handleChangeEmailForm(
                              "for_team",
                              true,
                              recipients
                            );
                          }
                    }
                  />
                  <span>{i18n._("for_team_explain")}</span>
                </MDBTooltip>
              </div>
            </div>
          </TabList>
        ) : (
          <MDBLink
            className={"text-center"}
            to={i18n._("BUTTONS.MORE_FEATURES.URL")}
          >
            <span className="lnr lnr-rocket"></span>
            {i18n._("BUTTONS.MORE_FEATURES.LABEL")}
          </MDBLink>
        )}

        {!!this.props.user?.role && (
          <>
            <TabPanel />
            <TabPanel disabled={!this.props.user?.role || transfer.isLocked}>
              <EmailFormComponent
                disabled={!this.props.user?.role}
                transferInfos={{ ...transferInfos }}
                handleChangeEmailForm={this.handleChangeEmailForm}
              />
            </TabPanel>
          </>
        )}

        <MDBModal
          className={"fv_uploader_form_email_form_modal"}
          isOpen={this.state.isPasswordModalOpen}
          centered
        >
          <MDBModalHeader
            toggle={() =>
              this.setState({ ...this.state, isPasswordModalOpen: false })
            }
          >
            {i18n._("form_upload_password_title")}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol size={"12"}>
                  <form
                    className="needs-validation"
                    onSubmit={this.handleSubmit}
                    noValidate
                  >
                    <MDBInput
                      autoComplete={"off"}
                      outline
                      group={false}
                      onChange={(evt: any) => {
                        this.setState({
                          ...this.state,
                          transferInfos: {
                            ...this.state.transferInfos,
                            [evt.target.name]: evt.target.value,
                          },
                        });
                      }}
                      onBlur={this.handleBlur}
                      label={i18n._("form_upload_password_label")}
                      type="password"
                      value={this.state.transferInfos.transfer_password}
                      name={"transfer_password"}
                      icon={"lock"}
                      iconClass={"fa-2x"}
                    >
                      <div className="valid-feedback">
                        {i18n._("form_upload_password_valid")}
                      </div>
                      <div className="invalid-feedback">
                        {i18n._("form_upload_password_invalid")}
                      </div>
                    </MDBInput>

                    <MDBInput
                      outline
                      group={false}
                      onChange={(v: any) =>
                        this.setState({
                          ...this.state,
                          transferInfos: {
                            ...this.state.transferInfos,
                            transfer_confirm_password: v.target.value,
                          },
                        })
                      }
                      pattern={this.state.transferInfos.transfer_password}
                      onBlur={this.handleBlur}
                      label={i18n._("form_profil_password_confirmation_label")}
                      type="password"
                      name={"transfer_confirm_password"}
                      icon={"lock"}
                      iconClass={"fa-2x"}
                    >
                      <div className="valid-feedback">
                        {i18n._("form_upload_confirm_password_valid")}
                      </div>
                      <div className="invalid-feedback">
                        {i18n._("form_upload_confirm_password_invalid")}
                      </div>
                    </MDBInput>
                    <MDBBtn
                      disabled={
                        !this.state.transferInfos.transfer_password?.length ||
                        !this.state.transferInfos.transfer_confirm_password
                          ?.length
                      }
                      type={"submit"}
                      color={"default"}
                      className={"waves-effect waves-light d-flex mx-auto"}
                    >
                      {i18n._("btn_valid_label")}
                    </MDBBtn>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>

        <MDBRow
          style={{ display: this.state.isFilesLoading ? "none" : "" }}
          className={"fv_autoremove_input_wrapper"}
        >
          <MDBCol size={"12"}>
            <MDBInput
              className={"fv_autoremove_input"}
              outline
              checked={transfer.auto_remove}
              label={i18n._("form_upload_transfer_auto_remove_label")}
              labelClass={"cus-label"}
              disabled={
                transfer.isLocked ||
                !(!transfer.recipients || transfer.recipients.length < 2)
              }
              onChange={this.handleChangeAutoremove}
              id={"auto-remove"}
              name={"auto_remove"}
              type={"checkbox"}
            >
              <div className="invalid-feedback">
                {i18n._("form_upload_transfer_auto_remove_invalid")}
              </div>
              <div className="valid-feedback">
                {i18n._("form_upload_transfer_auto_remove_valid")}
              </div>
            </MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ display: this.state.isFilesLoading ? "none" : "" }}>
          <MDBCol size={"12"}>
            <MDBBtn
              disabled={!!transfer.isLocked}
              onClick={this.handleCustomSubmit}
              className={
                "btn btn-block Ripple-parent aqua-gradient waves-effect waves-light py-2 mx-auto"
              }
            >
              {i18n._("btn_transfer_label")}
            </MDBBtn>

            {!isValidLimit && (
              <>
                <span className={"text-center text-danger"}>
                  {convertSize(this.props.user_upload_size.per_transfer_limit)}/
                  {i18n._("transfer_label")} max.
                </span>
                {/*<br/>*/}
                {/*<span className={`text-center text-danger ${this.props.user?.status ? '' : 'd-none'}`}>*/}
                {/*  {convertSize(this.props.user_upload_size.total_transfer_limit)}/cumulés max.*/}
                {/*</span>*/}
              </>
            )}
          </MDBCol>
        </MDBRow>
      </Tabs>
    );
  };
}

const mapStateToProps = (state: any) => ({
  app: state.app,
  user: state.user?.user,
  user_upload_size: {
    per_transfer_limit:
      state?.user?.user?.permissions?.upload_size?.value || defaultLimitInGo,
    total_transfer_limit: state?.user?.user?.limit_total || defaultLimitInGo,
  },
  current_uploaded_size:
    state?.user?.user?.current_uploaded_size || defaultLimitInGo,
  transfer: state.transfer.transfers,
  file: state.file,
});
export default connect(mapStateToProps)(UploaderFormComponent);
